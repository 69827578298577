import React, { useState, useRef, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Container, TextField, Button, Typography, Card, CardContent, CardMedia, CircularProgress, Alert, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './CheckDex.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Waitlist from './Waitlist'; // Import the Waitlist component
import TwitterIcon from './assets/Twitter (Default).svg'; // Import Twitter icon
import TelegramIcon from './assets/Telegram (Default).svg'; // Import Telegram icon
import WebsiteIcon from './assets/Website.svg';
import WebsiteIcon2 from './assets/Website2.svg';
import { toBlob } from 'html-to-image'; // Import toJpeg instead of toPng
import { useNavigate, useParams } from 'react-router-dom';
import SolanaIcon from './assets/sol.svg';
import EthereumIcon from './assets/eth.svg';
import TronIcon from './assets/tron.svg';
import BaseIcon from './assets/base.svg';
import tokenBackground2 from './assets/tokenbackground2.svg';
import MiniX from './assets/minix.svg';
import MiniTG from './assets/minitg.svg';
import MiniDown from './assets/minidown.svg'; // Import the logo
import ShareIcon from './assets/share.svg'; // Import the share icon
import { useMediaQuery } from '@mui/material';
import env from "react-dotenv";

const environment = env?.NODE_ENV as unknown as string; 
const backendURL = environment !== "development" ? "https://degenfi.ai/api" : "localhost:5000/api"
const CheckDex: React.FC = () => {
    const navigate = useNavigate();
    const { blockchain: urlBlockchain, contractAddress: urlContractAddress } = useParams<{ blockchain: string, contractAddress: string }>();

    const [contractAddress, setContractAddress] = useState(urlContractAddress || '');
    const [blockchain, setBlockchain] = useState(urlBlockchain || 'solana'); // Default to 'solana' if not in URL
    const [status, setStatus] = useState<string | null>(null);
    const [adStatus, setAdStatus] = useState<string | null>(null);
    const [communityStatus, setCommunityStatus] = useState<string | null>(null);
    const [trendingBarAdStatus, setTrendingBarAdStatus] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [tokenData, setTokenData] = useState<any | null>(null);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [openSharePopup, setOpenSharePopup] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [twitterIconHover, setTwitterIconHover] = useState(false);
    const [twitterIconPressed, setTwitterIconPressed] = useState(false);
    const [telegramIconHover, setTelegramIconHover] = useState(false);
    const [telegramIconPressed, setTelegramIconPressed] = useState(false);
    const [websiteIconHover, setWebsiteIconHover] = useState(false);
    const [websiteIconPressed, setWebsiteIconPressed] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        if (urlContractAddress && urlBlockchain) {
            handleCheck(urlContractAddress, urlBlockchain);
        }
    }, [urlContractAddress, urlBlockchain]);

    const formatMarketCap = (marketCap: number): string => {
        if (marketCap >= 1_000_000) {
            return `${(marketCap / 1_000_000).toFixed(2)}M`;
        } else if (marketCap >= 1_000) {
            return `${(marketCap / 1_000).toFixed(2)}K`;
        } else {
            return marketCap.toFixed(2);
        }
    };

    const fetchImageAsBase64 = async (imageUrl: string) => {
        try {
            const proxyUrl = "https://corsproxy.io/?";
            const response = await fetch(proxyUrl+imageUrl);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result); // Convert blob to base64
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error fetching the image:', error);
            return null;
        }
    };



    const handleCheck = async (address = contractAddress, chain = blockchain) => {
        if (!address) return;

        // Update the URL to /checker/blockchain/contract_address
        navigate(`/checker/${chain}/${address}`);

        setLoading(true);
        setError(null);
        setStatus(null);
        setAdStatus(null);
        setCommunityStatus(null);
        setTrendingBarAdStatus(null);
        setTokenData(null);

        try {
            // Fetch data from the Flask backend
            const response = await fetch(`${backendURL}/dexscreener/${chain}/${address}`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || `HTTP error! status: ${response.status}`);
            }

            let dexPaid = false;

            // Adjust the condition based on the actual response structure
            if (Array.isArray(data) && data.length > 0) {
                const tokenProfile = data.find((order: any) => order.type === 'tokenProfile');
                const tokenAd = data.find((order: any) => order.type === 'tokenAd');
                const trendingBarAd = data.find((order: any) => order.type === 'trendingBarAd');

                if (tokenProfile && tokenProfile.status === 'approved') {
                    setStatus('Dex is paid!!!');
                    dexPaid = true;
                } else  if (tokenProfile && tokenProfile.status === 'processing') {
                    setStatus('Dex is paid!!!');
                    dexPaid = true;
                }else {
                    setStatus('Dex is not paid.');
                }

                if (tokenAd && tokenAd.status === 'approved') {
                    setAdStatus('Dex ads are paid!!!');
                }

                if (trendingBarAd && trendingBarAd.status === 'approved') {
                    setTrendingBarAdStatus('Dex trending is paid!!!');
                }
            } else {
                setStatus('Dex is not paid.');
            }

            // Fetch additional token data using the Flask backend
            const tokenResponse = await fetch(`${backendURL}/token/${chain}/${address}`);
            const tokenData = await tokenResponse.json();
            if (!tokenResponse.ok) {
                throw new Error(tokenData.error || `HTTP error! status: ${tokenResponse.status}`);
            }

            const image = await fetchImageAsBase64(tokenData?.logo);
            setImageSrc(image as string);
             // Log the token data to debug
            setTokenData(tokenData);
        
            // Check for community status
            if (tokenData.creator_token_status === 'creator_close' && dexPaid) {
                setCommunityStatus('Community took over!!!');
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Failed to fetch data');
            }
        } finally {
            setLoading(false);
        }
    };

    const isValidContractAddress = (address: string, blockchain: string): boolean => {
        switch (blockchain) {
            case 'solana':
                const solanaRegex = /^[1-9A-HJ-NP-Za-km-z]+$/;
                return address.length >= 32 && address.length <= 44 && solanaRegex.test(address);
            case 'ethereum':
            case 'base':
                const ethBaseRegex = /^0x[a-fA-F0-9]{40}$/;
                return ethBaseRegex.test(address);
            case 'tron':
                const tronRegex = /^T[a-zA-Z0-9]{33}$/;
                return tronRegex.test(address);
            default:
                return false;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setContractAddress(value);

        if (value === '') {
            // Reset state when input is cleared
            setStatus(null);
            setAdStatus(null);
            setCommunityStatus(null);
            setTrendingBarAdStatus(null);
            setError(null);
            setTokenData(null);
            setValidationMessage(null);
            
            // Update the URL to the default
            navigate(`/checker/`); // Change to your default blockchain if needed
        } else if (!isValidContractAddress(value, blockchain)) {
            setValidationMessage('Please enter a valid token address');
        } else {
            setValidationMessage(null);
        }
    };

    const handleBlockchainChange = (newBlockchain: string) => {
        setBlockchain(newBlockchain);

        // Revalidate the contract address based on the new blockchain
        if (contractAddress === '') {
            setValidationMessage(null);
        } else if (!isValidContractAddress(contractAddress, newBlockchain)) {
            setValidationMessage('Please enter a valid token address');
        } else {
            setValidationMessage(null);
        }

        // Reset other states
        setStatus(null);
        setAdStatus(null);
        setCommunityStatus(null);
        setTrendingBarAdStatus(null);
        setError(null);
        setTokenData(null);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!contractAddress || validationMessage) {
                e.preventDefault(); // Prevent form submission if the address is empty or invalid
            } else {
                handleCheck();
            }
        }
    };

    const handleShare = () => {
        setOpenSharePopup(true);
    };

    const handleCloseSharePopup = () => {
        setOpenSharePopup(false);
    };

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            handleCloseSharePopup();
        }
    };
    const handleDownload = async () => {
        if (popupRef.current) {
            const blob = await toBlob(popupRef.current, { quality: 0.95 });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob as Blob);
            link.download = 'token-info.jpeg';
            link.click();
        }
    };

    const getShareMessage = () => {
        let message = 'Check out this token info!';
        if (status) message += ` ${status}`;
        if (adStatus) message += ` ${adStatus}`;
        if (communityStatus) message += ` ${communityStatus}`;
        message += ` ${window.location.href}`; // Append the current URL to the message
        return message;
    };

    const getTwitterShareUrl = () => {
        const message = getShareMessage();
        return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
    };

    const getTelegramShareUrl = () => {
        const message = getShareMessage();
        return `https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(message)}`;
    };

    // Function to handle Buy button click
    const handleBuy = () => {
        let buyLink = '';

        switch (blockchain) {
            case 'tron':
                buyLink = 'https://photon-tron.tinyastro.io/@degenfi';
                break;
            case 'base':
                buyLink = 'https://photon-base.tinyastro.io/@degenfi';
                break;
            case 'ethereum':
                buyLink = 'https://photon.tinyastro.io/@degenfi';
                break;
            case 'solana':
                buyLink = 'https://photon-sol.tinyastro.io/@degenfi';
                break;
            default:
                return; // Exit if no valid blockchain is selected
        }

        window.open(buyLink, '_blank'); // Open the link in a new tab
    };

    return (
        <Box sx={{ minHeight: '100vh', overflow: 'auto' }}>
            <Container
                className="checkdex-container"
                sx={{
                    backgroundColor: '#FCFCFD',
                    width: '40.4%', // 584px / 1444px * 100%
                    maxWidth: '584px',
                    margin: '35px auto',
                    padding: '20px',
                    borderRadius: '10px',
                    '@media (max-width: 1444px)': {
                        width: '90%', // Adjust width for smaller screens
                    },
                    '@media (min-width: 1200px)' : {
                        width: '584px', // Maintain original width for screens between 1200px and 1444px
                    },
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Token Checker 
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ margin: '0 auto 27px', fontWeight: 400, fontSize: '15px', }}>
                    Dex Upgrade, Ads, Trending, CTO instant checks.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '5px', maxWidth: '514px', margin: '0 auto', paddingBottom: '32px'}}>
                    <Button
                        variant={blockchain === 'solana' ? 'contained' : 'outlined'}
                        onClick={() => handleBlockchainChange('solana')}
                        startIcon={<img src={SolanaIcon} alt="Solana" style={{ width: '20px', height: '20px' }} />}
                        sx={{
                            width: '124.75px',
                            height: '44px',
                            textTransform: 'capitalize',
                            fontSize: '15px',
                            borderRadius:'999px',
                            backgroundColor: blockchain === 'solana' ? '#D9E2FC' : '#D9E2FC',
                            color: blockchain === 'solana' ? '#3A5CCC' : '#3A5CCC',
                            border: blockchain === 'solana' ? '1px solid #3A5CCC' : 'none',
                            '&:hover': {
                                backgroundColor: '#D9E2FC',
                                border: '1px solid #3A5CCC',
                            },
                            '&:active': {
                                backgroundColor: '#C6D4F9',
                                border: '1px solid #3A5CCC',
                            },
                        }}
                    >
                        {!isMobile && 'Solana'}
                    </Button>
                    <Button
                        variant={blockchain === 'ethereum' ? 'contained' : 'outlined'}
                        onClick={() => handleBlockchainChange('ethereum')}
                        startIcon={<img src={EthereumIcon} alt="Ethereum" style={{ width: '20px', height: '20px' }} />}
                        sx={{
                            width: '124.75px',
                            height: '44px',
                            textTransform: 'capitalize',
                            fontSize: '15px',
                            borderRadius:'999px',
                            backgroundColor: blockchain === 'ethereum' ? '#D9E2FC' : '#D9E2FC',
                            color: blockchain === 'ethereum' ? '#3A5CCC' : '#3A5CCC',
                            border: blockchain === 'ethereum' ? '1px solid #3A5CCC' : 'none',
                            '&:hover': {
                                backgroundColor: '#D9E2FC',
                                border: '1px solid #3A5CCC',
                            },
                            '&:active': {
                                backgroundColor: '#C6D4F9',
                                border: '1px solid #3A5CCC',
                            },
                        }}
                    >
                        {!isMobile && 'Ethereum'}
                    </Button>
                    <Button
                        variant={blockchain === 'tron' ? 'contained' : 'outlined'}
                        onClick={() => handleBlockchainChange('tron')}
                        startIcon={<img src={TronIcon} alt="Tron" style={{ width: '20px', height: '20px' }} />}
                        sx={{
                            width: '124.75px',
                            height: '44px',
                            textTransform: 'capitalize',
                            fontSize: '15px',
                            borderRadius:'999px',
                            backgroundColor: blockchain === 'tron' ? '#D9E2FC' : '#D9E2FC',
                            color: blockchain === 'tron' ? '#3A5CCC' : '#3A5CCC',
                            border: blockchain === 'tron' ? '1px solid #3A5CCC' : 'none',
                            '&:hover': {
                                backgroundColor: '#D9E2FC',
                                border: '1px solid #3A5CCC',
                            },
                            '&:active': {
                                backgroundColor: '#C6D4F9',
                                border: '1px solid #3A5CCC',
                            },
                        }}
                    >
                        {!isMobile && 'Tron'}
                    </Button>
                    <Button
                        variant={blockchain === 'base' ? 'contained' : 'outlined'}
                        onClick={() => handleBlockchainChange('base')}
                        startIcon={<img src={BaseIcon} alt="Base" style={{ width: '20px', height: '20px' }} />}
                        sx={{
                            width: '124.75px',
                            height: '44px',
                            textTransform: 'capitalize',
                            fontSize: '15px',
                            borderRadius:'999px',
                            backgroundColor: blockchain === 'base' ? '#D9E2FC' : '#D9E2FC',
                            color: blockchain === 'base' ? '#3A5CCC' : '#3A5CCC',
                            border: blockchain === 'base' ? '1px solid #3A5CCC' : 'none',
                            '&:hover': {
                                backgroundColor: '#D9E2FC',
                                border: '1px solid #3A5CCC',
                            },
                            '&:active': {
                                backgroundColor: '#C6D4F9',
                                border: '1px solid #3A5CCC',
                            },
                        }}
                    >
                        {!isMobile && 'Base'}
                    </Button>
                </Box>
                <Box sx={{ width: '100%', maxWidth: '514px', margin: '0 auto' }}>
                    <TextField
                        className="input-field"
                        variant="outlined"
                        placeholder="Enter contract address"
                        value={contractAddress}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        fullWidth
                        sx={{ width: '100%' }}
                        InputProps={{
                            sx: {
                                height: '44px',
                                '& .MuiOutlinedInput-input': {
                                    padding: '10px 14px',
                                },
                            },
                        }}
                    />
                    {validationMessage && (
                        <Typography variant="body2" color='#E5484D' sx={{ marginTop: '10px', textAlign: 'left' }}>
                            {validationMessage}
                        </Typography>
                    )}
                </Box>
                <Button
                    className="check-button"
                    variant="contained"
                    color="primary"
                    onClick={() => handleCheck()}
                    disabled={loading || !!validationMessage || !contractAddress} // Disable if loading, validation message exists, or input is empty
                    fullWidth
                    sx={{
                        marginTop: '20px',
                        borderRadius: '999px',
                        height: '44px',
                        width: '100%',
                        maxWidth: '514px',
                        textTransform: 'capitalize',
                        fontSize: '16px',
                        marginBottom: '20px',
                        backgroundColor: validationMessage || !contractAddress ? '#E4E4E9' : '#101D46', // Default color or disabled color
                        '&:hover': {
                            backgroundColor: validationMessage || !contractAddress ? '#E4E4E9' : '#3E63DD', // Hover color
                        },
                        '&:active': {
                            backgroundColor: validationMessage || !contractAddress ? '#E4E4E9' : '#8DA4EF', // Pressed color
                        },
                    }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Check Now'}
                </Button>
                {status && (
                    <Alert
                        severity={status === 'Dex is paid!!!' ? 'success' : 'error'}
                        sx={{
                            minHeight: '40px',
                            marginBottom: '10px',
                            padding: '4px 16px',
                            backgroundColor: 'transparent',
                            color: '#101D46',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiAlert-icon': {
                                width: '24px',
                                height: '24px',
                                color: status === 'Dex is paid!!!' ? '#03FF93' : '#F49092',
                            },
                            '& .MuiAlert-message': {
                                fontSize: '16px',
                            },
                        }}
                    >
                        {status}
                    </Alert>
                )}
                {adStatus && (
                    <Alert
                        severity="success"
                        sx={{
                            minHeight: '40px',
                            marginBottom: '10px',
                            padding: '4px 16px',
                            backgroundColor: 'transparent',
                            color: '#101D46',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiAlert-icon': {
                                width: '24px',
                                height: '24px',
                                color: '#03FF93',
                            },
                            '& .MuiAlert-message': {
                                fontSize: '16px',
                            },
                        }}
                    >
                        {adStatus}
                    </Alert>
                )}
                {communityStatus && (
                    <Alert
                        severity="success"
                        sx={{
                            minHeight: '40px',
                            marginBottom: '10px',
                            padding: '4px 16px',
                            backgroundColor: 'transparent',
                            color: '#101D46',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiAlert-icon': {
                                width: '24px',
                                height: '24px',
                                color: '#03FF93',
                            },
                            '& .MuiAlert-message': {
                                fontSize: '16px',
                            },
                        }}
                    >
                        {communityStatus}
                    </Alert>
                )}
                {trendingBarAdStatus && (
                    <Alert
                        severity="success"
                        sx={{
                            minHeight: '40px',
                            marginBottom: '10px',
                            padding: '4px 16px',
                            backgroundColor: 'transparent',
                            color: '#101D46',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiAlert-icon': {
                                width: '24px',
                                height: '24px',
                                color: '#03FF93',
                            },
                            '& .MuiAlert-message': {
                                fontSize: '16px',
                            },
                        }}
                    >
                        {trendingBarAdStatus}
                    </Alert>
                )}
                {error && (
                    <Alert
                        severity="error"
                        sx={{
                            minHeight: '40px',
                            marginBottom: '10px',
                            padding: '4px 16px',
                            backgroundColor: 'transparent',
                            color: '#101D46',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiAlert-icon': {
                                width: '24px',
                                height: '24px',
                                color: '#F49092',
                            },
                            '& .MuiAlert-message': {
                                fontSize: '16px',
                            },
                        }}
                    >
                        {error}
                    </Alert>
                )}
                {status === 'Dex is paid!!!' && (
                    <>
                        <Confetti
                            numberOfPieces={1000}
                            recycle={false}
                            gravity={0.3}
                            initialVelocityY={30}
                            initialVelocityX={50}
                            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight, w: 0, h: 0 }}
                            colors={['#101D46', '#3E63DD', '#8DA4EF']} // Updated colors to fit design
                        />
                    </>
                )}
                {tokenData && (
                    <Card
                        sx={{
                            marginTop: '20px',
                            textAlign: 'center',
                            backgroundImage: `url(${tokenBackground2})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            boxShadow: 'none',
                            border: 'none',
                            minHeight: '200px',
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 20px'}}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <img src={WebsiteIcon2} alt="Website Icon" style={{ width: '35px', height: '70px' }} />
                                <Typography variant="body2" sx={{ color: '#FCFCFD', fontSize: '36px' }}>Degenfi.ai</Typography>
                            </Box>
                        </Box>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <CardMedia
                                    component="img"
                                    image={imageSrc ?? tokenData.logo}
                                    alt={`${tokenData.name} logo`}
                                    sx={{
                                        width: '80px',
                                        height: '80px',
                                        marginBottom: '10px',
                                        border: '5.71px solid rgba(255, 255, 255, 0.169)', // Adjust stroke to remove space between border and stroke
                                        borderRadius: '50%', // Ensure the border is circular
                                        objectFit: 'cover', // Ensure the image covers the area
                                    }}
                                    onError={(e) => {
                                        console.error('Image failed to load:', e);
                                    }}
                                />
                                <Typography variant="h5" component="div" sx={{ color: '#FCFCFD' }}>
                                    {tokenData.name} ({tokenData.symbol})
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: status === 'Dex is paid!!!' || adStatus || communityStatus || trendingBarAdStatus ? '#03FF93' : '#F49092',
                                        fontWeight: 'bold',
                                        fontSize: '38px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {status === 'Dex is paid!!!' ? '👉 PAID!!! 🤑' : '👉 NOT PAID 🚫'}
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: '#F0F4FF',
                                        borderRadius: '8px',
                                        width: 'calc(100%)', // Adjusted width to take full card width minus 25px on each side
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        marginTop: '10px',
                                        textAlign: 'center',
                                        display: 'flex', // Use flexbox for layout
                                        justifyContent: 'center', // Space between items
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', marginRight: '25px'}}>
                                        <Typography variant="h6" sx={{ color: '#101D46' }}>
                                            {tokenData.holder_count}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: '#101D46' }}>
                                            Holder Count
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'center', marginLeft: '25px'}}>
                                        <Typography variant="h6" sx={{ color: '#101D46' }}>
                                            ${formatMarketCap(tokenData.market_cap)}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: '#101D46' }}>
                                            Market Cap
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                                {tokenData.twitter_username && (
                                    <a
                                        href={`https://twitter.com/${tokenData.twitter_username}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="Twitter"
                                            sx={{ width: '24px', height: '24px' }}
                                            onMouseEnter={() => setTwitterIconHover(true)} // Set hover state
                                            onMouseLeave={() => setTwitterIconHover(false)} // Reset hover state
                                            onMouseDown={() => setTwitterIconPressed(true)} // Set pressed state
                                            onMouseUp={() => setTwitterIconPressed(false)} // Reset pressed state
                                            src={twitterIconPressed ? require('./assets/twitter(pressed).svg').default : twitterIconHover ? require('./assets/twitter(hover).svg').default : TwitterIcon} // Change icon based on state
                                        />
                                    </a>
                                )}
                                {tokenData.telegram && (
                                    <a
                                        href={tokenData.telegram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="Telegram"
                                            sx={{ width: '24px', height: '24px' }}
                                            onMouseEnter={() => setTelegramIconHover(true)} // Set hover state
                                            onMouseLeave={() => setTelegramIconHover(false)} // Reset hover state
                                            onMouseDown={() => setTelegramIconPressed(true)} // Set pressed state
                                            onMouseUp={() => setTelegramIconPressed(false)} // Reset pressed state
                                            src={telegramIconPressed ? require('./assets/telegram(pressed).svg').default : telegramIconHover ? require('./assets/telegram(hover).svg').default : TelegramIcon} // Change icon based on state
                                        />
                                    </a>
                                )}
                                {tokenData.website && (
                                    <a
                                        href={tokenData.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="Website"
                                            sx={{ width: '24px', height: '24px' }}
                                            onMouseEnter={() => setWebsiteIconHover(true)} // Set hover state
                                            onMouseLeave={() => setWebsiteIconHover(false)} // Reset hover state
                                            onMouseDown={() => setWebsiteIconPressed(true)} // Set pressed state
                                            onMouseUp={() => setWebsiteIconPressed(false)} // Reset pressed state
                                            src={websiteIconPressed ? require('./assets/websitepressed.svg').default : websiteIconHover ? require('./assets/websitehovered.svg').default : WebsiteIcon} // Change icon based on state
                                        />
                                    </a>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                )}
                {tokenData && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleShare}
                            sx={{
                                borderRadius: '999px',
                                height: '44px',
                                width: '100%',
                                maxWidth: '250px',
                                textTransform: 'capitalize',
                                fontSize: '16px',
                                backgroundColor: validationMessage || !contractAddress ? '#E4E4E9' : '#101D46',
                                '&:hover': {
                                    backgroundColor: validationMessage || !contractAddress ? '#E4E4E9' : '#3E63DD',
                                },
                                '&:active': {
                                    backgroundColor: validationMessage || !contractAddress ? '#E4E4E9' : '#8DA4EF',
                                },
                            }}
                        >
                            Share
                            <img src={ShareIcon} alt="Share" style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleBuy} // Add your buy handler
                            sx={{
                                borderRadius: '999px',
                                height: '44px',
                                width: '100%',
                                maxWidth: '250px',
                                textTransform: 'capitalize',
                                fontSize: '16px',
                                backgroundColor: '#101D46',
                                '&:hover': {
                                    backgroundColor: '#3E63DD',
                                },
                                '&:active': {
                                    backgroundColor: '#8DA4EF',
                                },
                            }}
                        >
                            Buy
                            <img src={require('./assets/buy.svg').default} alt="Buy" style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                        </Button>
                    </Box>
                )}
                {openSharePopup && (
                    <Box
                        onClick={handleClickOutside}
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1300, // Add z-index to ensure the popup is on top
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                padding: '20px',
                                borderRadius: '10px',
                                width: '80%',
                                maxWidth: '450px',
                                position: 'relative',
                            }}
                        >
                            <Box ref={popupRef}>
                                <Card
                                    sx={{
                                        backgroundImage: `url(${tokenBackground2})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        boxShadow: 'none',
                                        border: 'none',
                                        minHeight: '200px', // Ensure the card has a minimum height to display the background image
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 20px'}}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <img src={WebsiteIcon2} alt="Website Icon" style={{ width: '35px', height: '70px' }} />
                                            <Typography variant="body2" sx={{ color: '#FCFCFD', fontSize: '36px' }}>Degenfi.ai</Typography>
                                        </Box>
                                    </Box>
                                    <CardContent sx={{ padding: '0px 16px 20px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {tokenData.logo && (
                                                <CardMedia
                                                    component="img"
                                                    image={imageSrc ?? tokenData.logo}
                                                    alt={`${tokenData.name} logo`}
                                                    sx={{
                                                        width: '80px',
                                                        height: '80px',
                                                        marginBottom: '10px',
                                                        border: '5.71px solid rgba(255, 255, 255, 0.169)', // Adjust stroke to remove space between border and stroke
                                                        borderRadius: '50%', // Ensure the border is circular
                                                        objectFit: 'cover', // Ensure the image covers the area
                                                    }}
                                                    onError={(e) => {
                                                        console.error('Image failed to load:', e);
                                                    }}
                                                />
                                            )}
                                            <Typography variant="h5" component="div" sx={{ color: '#FCFCFD' }}>
                                                {tokenData.name} ({tokenData.symbol})
                                            </Typography>
                                            <Box sx={{ width: 'calc(100% - 50px)', marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: status === 'Dex is paid!!!' || adStatus || communityStatus || trendingBarAdStatus ? '#03FF93' : '#F49092',
                                                    fontWeight: 'bold',
                                                    fontSize: '38px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {status === 'Dex is paid!!!' ? '👉 PAID!!! 🤑' : '👉 NOT PAID 🚫'}
                                            </Typography>
                                            {status && (
                                                <Alert
                                                    severity={status === 'Dex is paid!!!' ? 'success' : 'error'}
                                                    sx={{
                                                        minHeight: '40px', // Increased height for better visibility
                                                        padding: '2px 16px', // Reduced padding to decrease spacing
                                                        backgroundColor: 'transparent', // Remove background
                                                        color: '#FCFCFD', // Change text color
                                                        display: 'flex', // Center text and icon
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '& .MuiAlert-icon': {
                                                            width: '24px', // Set icon width
                                                            height: '24px', // Set icon height
                                                            color: status === 'Dex is paid!!!' ? '#03FF93' : '#F49092', // Update icon color
                                                        },
                                                        '& .MuiAlert-message': {
                                                            fontSize: '16px', // Set text size
                                                        },
                                                    }}
                                                >
                                                    {status}
                                                </Alert>
                                            )}
                                            {adStatus && (
                                                <Alert
                                                    severity="success"
                                                    sx={{
                                                        minHeight: '40px', // Increased height for better visibility
                                                        padding: '2px 16px', // Reduced padding to decrease spacing
                                                        backgroundColor: 'transparent', // Remove background
                                                        color: '#FCFCFD', // Change text color
                                                        display: 'flex', // Center text and icon
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '& .MuiAlert-icon': {
                                                            width: '24px', // Set icon width
                                                            height: '24px', // Set icon height
                                                            color: '#03FF93', // Update icon color
                                                        },
                                                        '& .MuiAlert-message': {
                                                            fontSize: '16px', // Set text size
                                                        },
                                                    }}
                                                >
                                                    {adStatus}
                                                </Alert>
                                            )}
                                            {communityStatus && (
                                                <Alert
                                                    severity="success"
                                                    sx={{
                                                        minHeight: '40px', // Increased height for better visibility
                                                        padding: '2px 16px', // Reduced padding to decrease spacing
                                                        backgroundColor: 'transparent', // Remove background
                                                        color: '#FCFCFD', // Change text color
                                                        display: 'flex', // Center text and icon
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '& .MuiAlert-icon': {
                                                            width: '24px', // Set icon width
                                                            height: '24px', // Set icon height
                                                            color: '#03FF93', // Update icon color
                                                        },
                                                        '& .MuiAlert-message': {
                                                            fontSize: '16px', // Set text size
                                                        },
                                                    }}
                                                >
                                                    {communityStatus}
                                                </Alert>
                                            )}
                                            {trendingBarAdStatus && (
                                                <Alert
                                                    severity="success"
                                                    sx={{
                                                        minHeight: '40px', // Increased height for better visibility
                                                        padding: '2px 16px', // Reduced padding to decrease spacing
                                                        backgroundColor: 'transparent', // Remove background
                                                        color: '#FCFCFD', // Change text color
                                                        display: 'flex', // Center text and icon
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '& .MuiAlert-icon': {
                                                            width: '24px', // Set icon width
                                                            height: '24px', // Set icon height
                                                            color: '#03FF93', // Update icon color
                                                        },
                                                        '& .MuiAlert-message': {
                                                            fontSize: '16px', // Set text size
                                                        },
                                                    }}
                                                >
                                                    {trendingBarAdStatus}
                                                </Alert>
                                            )}
                                            {error && (
                                                <Alert
                                                    severity="error"
                                                    sx={{
                                                        minHeight: '40px', // Increased height for better visibility
                                                        padding: '2px 16px', // Reduced padding to decrease spacing
                                                        backgroundColor: 'transparent', // Remove background
                                                        color: '#FCFCFD', // Change text color
                                                        display: 'flex', // Center text and icon
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '& .MuiAlert-icon': {
                                                            width: '24px', // Set icon width
                                                            height: '24px', // Set icon height
                                                            color: '#F49092', // Update icon color
                                                        },
                                                        '& .MuiAlert-message': {
                                                            fontSize: '16px', // Set text size
                                                        },
                                                    }}
                                                >
                                                    {error}
                                                </Alert>
                                            )}
                                            <Box
                                                sx={{
                                                    backgroundColor: '#F0F4FF',
                                                    borderRadius: '8px',
                                                    width: 'calc(100%)', // Adjusted width to take full card width minus 25px on each side
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    marginTop: '10px',
                                                    textAlign: 'center',
                                                    display: 'flex', // Use flexbox for layout
                                                    justifyContent: 'center', // Space between items
                                                }}
                                            >
                                                <Box sx={{ textAlign: 'center', marginRight: '25px'}}>
                                                    <Typography variant="h6" sx={{ color: '#101D46' }}>
                                                        {tokenData.holder_count}
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color: '#101D46' }}>
                                                        Holder Count
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ textAlign: 'center', marginLeft: '25px'}}>
                                                    <Typography variant="h6" sx={{ color: '#101D46' }}>
                                                        ${formatMarketCap(tokenData.market_cap)}
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color: '#101D46' }}>
                                                        Market Cap
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                                <a
                                    href={getTwitterShareUrl()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            borderRadius: '999px',
                                            backgroundColor: '#101D46', // Default color
                                            textTransform: 'capitalize', // Capitalize only the first letter
                                            '&:hover': {
                                                backgroundColor: '#3E63DD', // Hover color
                                            },
                                            '&:active': {
                                                backgroundColor: '#8DA4EF', // Pressed color
                                            },
                                        }}
                                    >
                                        Share
                                        <img src={MiniX} alt="X" style={{ width: '12px', height: '12px', marginLeft: '10px'}} /> {/* Add the share icon */}
                                    </Button>
                                </a>
                                <a
                                    href={getTelegramShareUrl()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            borderRadius: '999px',
                                            backgroundColor: '#101D46', // Default color
                                            textTransform: 'capitalize', // Capitalize only the first letter
                                            '&:hover': {
                                                backgroundColor: '#3E63DD', // Hover color
                                            },
                                            '&:active': {
                                                backgroundColor: '#8DA4EF', // Pressed color
                                            },
                                        }}
                                    >
                                        Share
                                        <img src={MiniTG} alt="TG" style={{ width: '12px', height: '12px', marginLeft: '10px'}} /> {/* Add the share icon */}
                                    </Button>
                                </a>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDownload}
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '12px',
                                        borderRadius: '999px',
                                        backgroundColor: '#101D46', // Default color
                                        textTransform: 'capitalize', // Capitalize only the first letter
                                        '&:hover': {
                                            backgroundColor: '#3E63DD', // Hover color
                                        },
                                        '&:active': {
                                            backgroundColor: '#8DA4EF', // Pressed color
                                        },
                                    }}
                                >
                                    Download
                                    <img src={MiniDown} alt="X" style={{ width: '12px', height: '12px', marginLeft: '10px'}} /> {/* Add the share icon */}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Container>
            <Waitlist />
            <Container
                sx={{
                    backgroundColor: 'transparent', // No background color
                    width: '43.7%', // 584px / 1444px * 100%
                    maxWidth: '632px',
                    margin: '35px auto',
                    padding: '0px', // Remove default padding
                    '@media (max-width: 1444px)': {
                        width: '90%', // Adjust width for smaller screens
                    },
                    '@media (min-width: 1444px)': {
                        width: '632px', // Maintain original width for screens between 1200px and 1444px
                    },
                }}
            >
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', marginBottom: '20px', color: '#101D46' }}>
                    FAQs
                </Typography>
                <Box sx={{ marginBottom: '5px'}}>
                    <Accordion
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#101D46' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="body1" sx={{ color: '#101D46' }}><strong>Do you check for Dexscreener token pages upgrade?</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: '#101D46' }}>
                                Yes, DegenFi verifies when a token upgrades their page on Dexscreener. It shows involvement from the team's projects and can indicate a potential community takeover if the original creators has abandoned the project (see below).
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ marginBottom: '5px' }}>
                    <Accordion
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                            borderTop: '1px solid #101D46',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#101D46' }} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="body1" sx={{ color: '#101D46' }}><strong>Do you check for Dexscreener token ads and trending ads?</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: '#101D46' }}>
                                Yes, DegenFi checks for tokens running promotional advertisements on the Dexscreener platform, this includes both the basic ads package ($299 USD) and the trending ads package ($2000 USD).
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ marginBottom: '5px' }}>
                    <Accordion
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                            borderTop: '1px solid #101D46',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#101D46' }} />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="body1" sx={{ color: '#101D46' }}><strong>Do you check for community takeovers (CTO)?</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: '#101D46' }}>
                                Yes, DegenFi identifies when a token's community has taken over the project, it usually happens after the original creators abandon its community and they decide to not let the project die. The first step is restarting with new social media links (e.g. a Telegram group, an X (Twitter) page or a Discord server), this way the community can set new admins and publish fresh content.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ marginBottom: '5px' }}>
                    <Accordion
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                            borderTop: '1px solid #101D46',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#101D46' }} />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography variant="body1" sx={{ color: '#101D46' }}><strong>What is DegenFi?</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: '#101D46' }}>
                                DegenFi is building the ultimate decentralized trading platform to maximize speed, efficiency and profit, offering advanced trading tools such as automation, copy trading, useful analytics and a variety of signals.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box> 
            </Container>
        </Box>
    );
};

export default CheckDex;