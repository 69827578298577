import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import CheckDex from './CheckDex';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/checker" element={<CheckDex />} />
          <Route path="/" element={<Navigate to="/checker" />} />
          <Route path="/checker/:blockchain/:contractAddress" element={<CheckDex/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;