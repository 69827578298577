import React, { useState } from 'react';
import './Navbar.css'; // Optional: for styling
import logo from '../assets/logo.svg'; // Adjust the path to your logo
import logomark from '../assets/logomark.svg'; // Adjust the path to your logomark
import twitterIcon from '../assets/Twitter (Default).svg'; // Import Twitter icon
import twitterIconHover from '../assets/twitter(hover).svg'; // Import Twitter hover icon
import twitterIconPressed from '../assets/twitter(pressed).svg'; // Import Twitter pressed icon
import telegramIcon from '../assets/Telegram (Default).svg'; // Import Telegram icon
import telegramIconHover from '../assets/telegram(hover).svg'; // Import Telegram hover icon
import telegramIconPressed from '../assets/telegram(pressed).svg'; // Import Telegram pressed icon

function Navbar() {
  const [twitterSrc, setTwitterSrc] = useState(twitterIcon);
  const [telegramSrc, setTelegramSrc] = useState(telegramIcon);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Logo" className="navbar-logo" />
        <img src={logomark} alt="Logomark" className="navbar-logomark" />
      </div>
      <ul className="navbar-center">
      </ul>
      <div className="navbar-right">
        <a href="https://twitter.com/degenfiai" target="_blank" rel="noopener noreferrer"
          onMouseEnter={() => setTwitterSrc(twitterIconHover)}
          onMouseLeave={() => setTwitterSrc(twitterIcon)}
          onMouseDown={() => setTwitterSrc(twitterIconPressed)}
          onMouseUp={() => setTwitterSrc(twitterIconHover)}>
          <img src={twitterSrc} alt="Twitter" className="navbar-icon" />
        </a>
        <a href="https://t.me/degenfi_alerts" target="_blank" rel="noopener noreferrer"
          onMouseEnter={() => setTelegramSrc(telegramIconHover)}
          onMouseLeave={() => setTelegramSrc(telegramIcon)}
          onMouseDown={() => setTelegramSrc(telegramIconPressed)}
          onMouseUp={() => setTelegramSrc(telegramIconHover)}>
          <img src={telegramSrc} alt="Telegram" className="navbar-icon" />
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
