import React, { useState } from 'react';
import { Container, Button, Typography, Snackbar, Alert, Box, IconButton } from '@mui/material';
import logo from './assets/LogoDark.svg'; // Import the logo
import closeIcon from './assets/close.svg'; // Import the custom close icon

const Waitlist: React.FC = () => {
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInput(value);

        if (value === '') {
            setValidationMessage(null);
        } else if (!isValidEmail(value) && !isValidTelegram(value)) {
            setValidationMessage('Please enter a valid email or Telegram username');
        } else {
            setValidationMessage(null);
        }
    };

    const handleJoinWaitlist = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            // Simulate API call
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setSuccess("You're on the list! 🎉");
        } catch (err) {
            setError('Failed to join the waitlist. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleJoinWaitlist();
        }
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidTelegram = (username: string): boolean => {
        const telegramRegex = /^@[a-zA-Z0-9_]{5,}$/;
        return telegramRegex.test(username);
    };

    const handleCloseSnackbar = () => {
        setError(null);
        setSuccess(null);
    };

    return (
        <Container
            sx={{
                backgroundColor: '#D9E2FC',
                width: '40.4%',
                maxWidth: '584px',
                margin: '35px auto',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center', // Center the content
                '@media (max-width: 1444px)': {
                    width: '90%',
                },
                '@media (min-width: 1200px)': {
                    width: '584px',
                },
            }}
        >
            <img src={logo} alt="Logo" style={{ width: '130px', margin: '16.5px auto 6.5px' }} /> {/* Add the logo */}
            <Typography gutterBottom sx={{ margin: '5px auto 16px', fontWeight: 400, fontSize: '15px' }}> {/* Reduced margin */}
                Receive Smart Signal Alerts in Real-Time!
            </Typography>
            {/* Hide the input and join button */}
            {/* <Box sx={{ width: '100%', maxWidth: '514px', margin: '0 auto' }}>
                <TextField
                    variant="outlined"
                    placeholder="Enter your email or Telegram username"
                    value={input}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    sx={{ width: '100%' }}
                    InputProps={{
                        sx: {
                            height: '44px',
                            '& .MuiOutlinedInput-input': {
                                padding: '10px 14px',
                            },
                        },
                    }}
                />
                {validationMessage && (
                    <Typography variant="body2" color='#E5484D' sx={{ marginTop: '10px', textAlign: 'left' }}>
                        {validationMessage}
                    </Typography>
                )}
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={handleJoinWaitlist}
                disabled={loading || !!validationMessage || !input}
                fullWidth
                sx={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    borderRadius: '999px',
                    height: '44px',
                    width: '100%',
                    maxWidth: '514px',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    backgroundColor: validationMessage || !input ? '#E4E4E9' : '#101D46',
                    '&:hover': {
                        backgroundColor: validationMessage || !input ? '#E4E4E9' : '#3E63DD',
                    },
                    '&:active': {
                        backgroundColor: validationMessage || !input ? '#E4E4E9' : '#8DA4EF',
                    },
                }}
            >
                {loading ? <CircularProgress size={24} /> : 'Join Now'}
            </Button> */}
            {/* New CTA button for signals channel */}
            <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://t.me/degenfi_alerts', '_blank')}
                fullWidth
                sx={{
                    marginBottom: '20px',
                    marginTop: '10px',
                    borderRadius: '999px',
                    height: '44px',
                    width: '100%',
                    maxWidth: '514px',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    backgroundColor: '#101D46',
                    '&:hover': {
                        backgroundColor: '#3E63DD',
                    },
                }}
            >
                Join now
            </Button>
            <Snackbar
                open={!!error || !!success}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ marginTop: '40px' }} // Position 40px from the top
            >
                {error ? (
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="error"
                        sx={{
                            width: '355px',
                            height: '55px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            border: '1px solid #DDDDE3',
                            boxSizing: 'border-box',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloseSnackbar}
                            >
                                <img src={closeIcon} alt="close" style={{ width: '55px', height: '25px' }} />
                            </IconButton>
                        }
                    >
                        {error}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        sx={{
                            width: '355px',
                            height: '55px',
                            backgroundColor: '#F8F9FB',
                            color: '#000',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            border: '1px solid #DDDDE3',
                            fontSize: '15px',
                            padding: '0 15px',
                            boxSizing: 'border-box',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        icon={false} // Remove the icon
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloseSnackbar}
                            >
                                <img src={closeIcon} alt="close" style={{ width: '55px', height: '25px' }} />
                            </IconButton>
                        }
                    >
                        <Box sx={{ padding: '15.5px 0' }}>
                            You're on the list! 🎉
                        </Box>
                    </Alert>
                )}
            </Snackbar>
        </Container>
    );
};

export default Waitlist;