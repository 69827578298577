import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Sora, sans-serif',
    h1: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    body2: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
    },
    button: {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '999px',
          backgroundColor: '#FCFCFD',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #DDDDE3',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AEC0F5',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #3A5CCC',
          },
          '&.MuiInputBase-root.MuiFilledInput-root': {
            backgroundColor: '#D9E2FC',
            border: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
        input: {
          fontFamily: 'Sora, sans-serif',
          fontWeight: 400,
          borderRadius: '999px',
          backgroundColor: '#FCFCFD',
          color: '#1C2024', // Ensure text color is set
          '&::placeholder': {
            color: '#1C2024', // Ensure placeholder text color is set
          },
          '&:hover': {
            backgroundColor: '#F8FAFF',
            color: '#1C2024', // Ensure text color is set
            fontWeight: 400,
          },
          '&.Mui-focused': {
            backgroundColor: '#FCFCFD',
            color: '#1C2024', // Ensure text color is set
            fontWeight: 400,
          },
          '&.MuiInputBase-root.MuiFilledInput-root': {
            backgroundColor: '#D9E2FC',
            color: '#1C2024', // Ensure text color is set
            fontWeight: 400,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#1C2024', // Ensure text color is set for MuiInputBase
          fontWeight: 400,
        },
      },
    },
  },
});

export default theme;
